import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { ButtonRecipe } from '../components/Button.css';
import Hero, { HeroProps } from '../components/Hero';
import Layout, { Section } from '../components/Layout';
import Link from '../components/Link';
import { sprinkles } from '../sprinkles.css';

import FastSimpleCompliant from '../images/fast-simple-compliant.svg';
import PartnershipAmico from '../images/index-partnership-amico.svg';
import SoftwareSoftSkillsAmico from '../images/index-software-soft-skills-amico.svg';
import ReadingListAmico1 from '../images/reading-list-amico-1.svg';

import { graphql } from 'gatsby';
import { BlogTeaserSection } from '../components/BlogTeaser';
import CustomerLogos from '../components/CustomerLogos';
import FaqSection from '../components/FaqSection';
import FullWidthTabs from '../components/FullWidthTabs';
import QuickInsights from '../components/QuickInsights';
import TestimonialSection from '../components/Testimonial';
import { Title } from '../components/Text';
import { Seo } from '../components/Seo';

const IndexPage = ({
	data: { blogs, allFaqJson, allQuickInsightsJson, allTestimonialsJson },
}: {
	data: {
		sitePage: Queries.SitePage;
		locales: { edges: Queries.LocaleEdge[] };
		blogs: { edges: Queries.MdxEdge[] };
		allFaqJson: { nodes: Queries.FaqJson[] };
		allQuickInsightsJson: { nodes: Queries.QuickInsightsJson[] };
		allTestimonialsJson: { nodes: Queries.TestimonialsJson[] };
	};
}) => {
	const { t } = useTranslation('IndexPage');
	const heroContent: HeroProps = {
		title: (
			<Title level="title">
				<Trans i18nKey="Hero.Title" />
			</Title>
		),
		text: <Trans i18nKey="Hero.Text_html" />,
		actions: [
			<Link
				key={1}
				to="https://my.meetergo.com/martinmeng/hinschg"
				className={ButtonRecipe({ size: 'large', variant: 'primary' })}
			>
				{t('Hero.Cta1Text')}
			</Link>,
			// <Link
			// 	key={2}
			// 	to="https://my.meetergo.com/martinmeng/hinschg"
			// 	className={ButtonRecipe({ size: 'large', variant: 'secondaryText' })}
			// >
			// 	Get advice now
			// </Link>,
		],
		image: (
			<img
				alt="konfidal: Fast simple compliant"
				src={FastSimpleCompliant}
				className={sprinkles({
					width: 'fill',
					minWidth: {
						mobile: 'three',
						tablet: 'fourhalf',
					},
				})}
			/>
		),
	};

	const quickInsightsContent = {
		...allQuickInsightsJson?.nodes[0],
		insights: allQuickInsightsJson?.nodes[0].insights.map((insight) => ({
			...insight,
		})),
	};

	return (
		<Layout padding={false} limitWidth={false}>
			<Section direction="vertical" width="fill" gap="giant">
				<Hero {...heroContent} width="wide" />
				<CustomerLogos />
				<FullWidthTabs
					content={[
						{
							title: t('Tabs.Consulting.Title'),
							text: <Trans i18nKey="Tabs.Consulting.Text_html" />,
							ctaLabel: t('Tabs.Consulting.CtaLabel'),
							image: (
								<img
									width="100%"
									style={{ maxHeight: '100%' }}
									src={ReadingListAmico1}
									alt={t('Tabs.Consulting.ImageAlt')}
								/>
							),
						},
						{
							title: t('Tabs.Software.Title'),
							text: <Trans i18nKey="Tabs.Software.Text_html" />,
							ctaLabel: t('Tabs.Software.CtaLabel'),
							image: (
								<img
									width="100%"
									style={{ maxHeight: '100%' }}
									src={SoftwareSoftSkillsAmico}
									alt={t('Tabs.Software.ImageAlt')}
								/>
							),
						},
						{
							title: t('Tabs.FullService.Title'),
							text: <Trans i18nKey="Tabs.FullService.Text_html" />,
							ctaLabel: t('Tabs.FullService.CtaLabel'),
							image: (
								<img
									width="100%"
									style={{ maxHeight: '100%' }}
									src={PartnershipAmico}
									alt={t('Tabs.FullService.ImageAlt')}
								/>
							),
						},
					]}
				/>
				{allQuickInsightsJson?.nodes[0] && <QuickInsights {...quickInsightsContent} />}
				{blogs && <TestimonialSection testimonials={allTestimonialsJson?.nodes} />}
				<BlogTeaserSection
					posts={blogs.edges
						.map(({ node }) => node)
						.sort(
							(a, b) =>
								(b.frontmatter?.lastmod &&
									a.frontmatter?.lastmod?.localeCompare(b.frontmatter?.lastmod)) ||
								0,
						)
						.slice(0, 2)}
				/>
			</Section>
			<FaqSection
				content={allFaqJson.nodes[0].entries.filter((e) => e.pages.includes('/'))}
				width="wide"
			/>
		</Layout>
	);
};
export default IndexPage;

export const Head = () => {
	return <Seo />;
};

export const pageQuery = graphql`
	query ($language: String!) {
		sitePage {
			context {
				i18n {
					originalPath
				}
			}
		}
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		blogs: allMdx(
			filter: {
				fileAbsolutePath: { glob: "**/blog/**" }
				frontmatter: { language: { eq: $language }, path: { ne: "how-to-implement" } }
			}
		) {
			edges {
				node {
					id
					frontmatter {
						title
						published_at
						path
						author
						featured_image {
							src {
								childImageSharp {
									gatsbyImageData(layout: FULL_WIDTH)
								}
							}
							alt
						}
					}
					excerpt(pruneLength: 250)
					internal {
						contentFilePath
					}
				}
			}
		}
		allFaqJson(filter: { language: { eq: $language } }) {
			nodes {
				entries {
					title
					content
					pages
				}
			}
		}
		allQuickInsightsJson(filter: { language: { eq: $language } }) {
			nodes {
				title
				subtitle
				description
				insights {
					title
					text
					icon
				}
			}
		}
		allTestimonialsJson(
			filter: { language: { eq: $language }, slug: { in: ["o-group", "ferox-legal"] } }
		) {
			nodes {
				name
				company
				text
				companyLogo {
					childImageSharp {
						gatsbyImageData(height: 50)
					}
				}
				companyLogoAlt
				personImage {
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
					}
				}
				personImageAlt
				link
			}
		}
	}
`;
